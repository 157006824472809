<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ contract.code }}</h3>
          <!-- <h3>{{ contract.name }}</h3> -->
        </div>

        <div class="col-6 text-right">
          <base-button
            class="elite-button btn-outline-danger"
            icon
            v-if="$currentUserCan($permissions.PERM_DELETE_CONTRACTS)"
            @click="deleteContract"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            v-if="$currentUserCan($permissions.PERM_EDIT_CONTRACTS)"
            @click="editContract"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="contract.contract_type">
          <dt>{{ $t("COMMON.TYPE") }}</dt>
          <dd>
            {{
              contract.contract_type
                ? $t("CONTRACTS." + contract.contract_type)
                : ""
            }}
          </dd>
        </dl>
        <dl class="row" v-if="contract.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ contract.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="contract.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ contract.excerpt ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="contract.slug">
          <dt>{{ $t("COMMON.SLUG") }}</dt>
          <dd>
            {{ contract.slug ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="contract.undetermined">
          <dt>{{ $t("CONTRACTS.UNDETERMINED") }}</dt>
          <dd>
            {{ $t("COMMON.YES") }}
          </dd>
        </dl>

        <dl class="row" v-if="contract.undetermined && contract.duration">
          <dt>{{ $t("CONTRACTS.DURATION") }}</dt>
          <dd>{{ contract.duration ?? "" }} {{ $t("COMMON.MOUNTH") }}</dd>
        </dl>
        
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && contract.organization"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="contract.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(contract.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(contract.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contract-view-global",

  props: ["contract"],

  data() {
    return {};
  },

  created() {},

  methods: {
    contractUpdated() {
      this.$emit("contractUpdated", true);
    },
    editContract() {
      this.$emit("onEditContract", this.contract);
    },
    deleteContract() {
      this.$emit("onDeleteContract", this.contract);
    },
  },

  mounted() {},

  watch: {
    contract(contract) {},
  },
};
</script>
