export default {
  type: "contracts",
  contract_type: null,
  email_message: null,
  undetermined:false,
  duration:null,
  name: null,
  slug: null,
  excerpt: null,
  meta: null,
  model_id: null,
  relationshipNames: ["organization","files"],
  organization: {
    type: "organizations",
    id: null,
  },
  files: [],
  roles: [],
};
