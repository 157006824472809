<template>
  <div class="container-fluid">
    <contract-form
      :loading="loading"
      :contractData="contract"
      :formErrors="formErrors"
      @contractSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultContract from "../defaultContract";
import ContractForm from "../partials/ContractForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ContractForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      contract: cloneDeep(defaultContract),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(contract) {
      this.loading = true;

      const contractData = cloneDeep(contract);
      delete contractData.id;

      try {
        await this.$store.dispatch("contracts/add", contractData);
        this.$notify({
          type: "success",
          message: this.$t("CONTRACTS.CONTRACT_ADDED"),
        });
        const contract = await this.$store.getters[
          "contracts/contract"
        ];
        this.$emit("onViewContract", contract, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.formErrors = error.response.data.errors;
      }
    },
  },
};
</script>
